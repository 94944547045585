.lopCustomSection {
  float: left;
  width: 100%; }
  .lopCustomSection input::placeholder {
    font-style: normal; }
  .lopCustomSection .copiedClip {
    background: #2251ff;
    color: #ffffff;
    opacity: 0.8;
    position: fixed;
    top: -40px;
    right: 100px;
    padding: 4px 20px;
    border-radius: 20px;
    bottom: 50px;
    -webkit-transition-property: top;
    -webkit-transition-duration: 0.5s;
    height: 30px;
    z-index: 1000; }
    .lopCustomSection .copiedClip.showCopy {
      top: 105px; }
  .lopCustomSection .addCustomContent {
    background-color: #f2f2f2;
    padding: 52px 30px 38px;
    float: left;
    width: 100%;
    text-align: center; }
    .lopCustomSection .addCustomContent .buttonContent {
      border-top: none; }
    .lopCustomSection .addCustomContent h3 {
      font-size: 1.5rem;
      font-weight: 300;
      color: #4a4a4a; }
    .lopCustomSection .addCustomContent .contentHeader {
      align-items: center;
      justify-content: center;
      margin: 24px 0 60px 0; }
      .lopCustomSection .addCustomContent .contentHeader > div {
        border-radius: 18px;
        background-color: #ffffff;
        padding: 8px 24px;
        margin: 0 20px;
        cursor: pointer; }
        .lopCustomSection .addCustomContent .contentHeader > div:first-child, .lopCustomSection .addCustomContent .contentHeader > div:last-child {
          margin: 0; }
        .lopCustomSection .addCustomContent .contentHeader > div.highlighted {
          background: #2251ff;
          color: #ffffff; }
    .lopCustomSection .addCustomContent .contentItems {
      align-items: baseline;
      justify-content: center;
      margin: 24px 0 32px 0; }
      .lopCustomSection .addCustomContent .contentItems div {
        font-size: 0.6875rem;
        font-weight: bold;
        color: #333333;
        margin-left: 74px;
        cursor: pointer; }
        .lopCustomSection .addCustomContent .contentItems div.selectorType2 {
          margin-left: 50px; }
        .lopCustomSection .addCustomContent .contentItems div:first-child {
          margin: 0; }
        .lopCustomSection .addCustomContent .contentItems div.highlighted {
          cursor: default; }
        .lopCustomSection .addCustomContent .contentItems div svg {
          display: block;
          margin-bottom: 8px; }
  .lopCustomSection .customSectionContainer {
    float: left;
    width: 100%; }

.customSectionContent {
  width: 100% !important;
  padding: 60px 45px 45px;
  background: #ffffff; }
  @media (max-width: 1200px) {
    .customSectionContent {
      padding: 60px 50px 45px; } }
  .customSectionContent .dummyHeightContent {
    position: absolute;
    top: -70px;
    left: 0; }
  .customSectionContent.docContent {
    min-height: inherit;
    /* padding: 60px 45px; */ }
    .customSectionContent.docContent .docIconContent {
      font-size: 0.875rem;
      font-weight: 500;
      color: #2251ff;
      text-align: center;
      margin-top: 20px;
      text-transform: uppercase; }
      .customSectionContent.docContent .docIconContent span {
        cursor: pointer; }
        .customSectionContent.docContent .docIconContent span i {
          background: #2251ff;
          border-radius: 50%;
          display: inline-block;
          margin-left: 14px; }
        .customSectionContent.docContent .docIconContent span.previewContent i {
          background-color: #ffffff;
          border-radius: 0;
          margin-left: 10px; }
  .customSectionContent.textContent {
    width: 100%; }
  .customSectionContent.headingContent {
    padding-top: 5px;
    padding-bottom: 10px;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .customSectionContent.headingContent .heading {
      font-size: 1.375rem;
      text-align: center;
      font-weight: bold; }
  .customSectionContent.textContent {
    min-height: 200px;
    padding: 42px 100px 42px 100px; }
  .customSectionContent .controls {
    margin: 20px 0 15px 10px; }
    .customSectionContent .controls .deletePointer {
      right: 100px; }
  .customSectionContent .heading-text {
    width: 100%; }
  .customSectionContent .heading-text,
  .customSectionContent .text-area {
    display: block;
    margin-top: 50px;
    padding: 0 150px; }
    .customSectionContent .heading-text input,
    .customSectionContent .text-area input {
      height: 80px;
      border: 1px dashed #ced4da;
      font-size: 1.125rem; }
  .customSectionContent .textContent {
    float: right;
    position: relative;
    width: 34%; }
    .customSectionContent .textContent .borderDesc {
      width: 100%;
      height: 1px;
      background: #000000;
      margin-bottom: 16px; }
    .customSectionContent .textContent.fullTextContent {
      width: 100%;
      text-align: center; }
      .customSectionContent .textContent.fullTextContent .borderDesc {
        display: none; }
      .customSectionContent .textContent.fullTextContent .contentHeader {
        padding: 0 0 8px 0; }
        .customSectionContent .textContent.fullTextContent .contentHeader input {
          margin-left: 25%;
          width: 50%; }
  .customSectionContent .deletePointer {
    top: 12px;
    box-shadow: 0 1px 4px 4px rgba(145, 145, 145, 0.5);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    padding-top: 6px; }
    .customSectionContent .deletePointer svg > g > g {
      fill: #000000; }
  .customSectionContent .copyPointer {
    top: 12px;
    right: 165px;
    box-shadow: 0 1px 4px 4px rgba(145, 145, 145, 0.5);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    position: absolute; }
  .customSectionContent .contentHeader {
    padding: 0 0 16px 0 !important;
    margin-bottom: 0 !important; }
    .customSectionContent .contentHeader h2,
    .customSectionContent .contentHeader .contentTitleText {
      padding: 0 0 4px 0; }
    .customSectionContent .contentHeader .contentTitleText {
      margin: 0 0 8px 0;
      padding: 0 0 4px 8px; }
  .customSectionContent .desc {
    height: inherit;
    max-height: 313px;
    padding: 0;
    overflow: hidden; }
    .customSectionContent .desc ul {
      margin: 0;
      padding-inline-start: 24px; }
      .customSectionContent .desc ul li {
        margin-bottom: 4px; }
  .customSectionContent .readMore {
    color: #2251ff;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 16px; }
  .customSectionContent .pointerTextTool {
    height: 270px; }
    .customSectionContent .pointerTextTool > div:last-child {
      max-height: 200px; }

.videoLinkModal {
  padding: 0;
  width: 60%;
  max-width: 600px;
  box-shadow: 2px 6px 20px -3px #000000;
  background-color: #ffffff;
  color: #4a4a4a;
  min-height: 280px; }
  .videoLinkModal .styles_closeButton__20ID4 {
    top: 14px !important;
    right: 14px !important;
    background: transparent;
    display: flex; }
  .videoLinkModal h2 {
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin: 28px 0; }
  .videoLinkModal .videoContent {
    padding: 0 26px; }
    .videoLinkModal .videoContent h3 {
      font-size: 0.9375rem;
      font-weight: 500;
      line-height: 1.8;
      text-align: center; }
    .videoLinkModal .videoContent .videoLinkTitle {
      border-radius: 4px;
      border: 1px solid #2251ff;
      background-color: #ffffff; }
    .videoLinkModal .videoContent .info {
      font-size: 0.8125rem;
      margin: 18px 0;
      text-align: center;
      opacity: 0.7; }
  .videoLinkModal .buttonContent {
    border-top: 1px solid #e5e5ea;
    padding-top: 24px;
    text-align: center; }
    .videoLinkModal .buttonContent .doneButton {
      display: inline-block;
      width: 130px;
      height: 45px;
      border-radius: 2px;
      color: #ffffff;
      background: #2251ff;
      padding: 10px 0;
      cursor: pointer; }
    .videoLinkModal .buttonContent .closeButton {
      display: inline-block;
      width: 130px;
      height: 45px;
      border-radius: 2px;
      color: #2251ff;
      background: #ffffff;
      margin-top: 20px;
      padding: 0 0 10px 0;
      cursor: pointer; }

.multiVideoLinkModal {
  padding: 10px 40px;
  width: 70%;
  max-width: 700px;
  box-shadow: 2px 6px 20px -3px #000000;
  background-color: #ffffff;
  color: #4a4a4a;
  max-height: 750px; }
  .multiVideoLinkModal .styles_closeButton__20ID4 {
    top: 14px !important;
    right: 14px !important;
    background: transparent;
    display: flex; }
  .multiVideoLinkModal h2 {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    text-align: center;
    margin: 28px 0 0 0; }
  .multiVideoLinkModal .instruction {
    font-size: 12px;
    margin: 4px 0 28px 0;
    display: flex;
    justify-content: center; }
  .multiVideoLinkModal .videoContent {
    max-height: 500px;
    overflow: auto;
    width: 100%;
    padding: 0 20px; }
    .multiVideoLinkModal .videoContent .message {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;
      border: 1px dashed;
      font-size: 13px; }
    .multiVideoLinkModal .videoContent .video-input-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      background: #f2f2f2;
      margin-bottom: 25px; }
      .multiVideoLinkModal .videoContent .video-input-container .vid-header {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09); }
        .multiVideoLinkModal .videoContent .video-input-container .vid-header .serial {
          width: 10%;
          margin-left: 20px;
          font-size: 16px; }
        .multiVideoLinkModal .videoContent .video-input-container .vid-header .controls {
          display: flex;
          justify-content: space-around;
          width: 100%; }
          .multiVideoLinkModal .videoContent .video-input-container .vid-header .controls span {
            width: 100%;
            justify-content: center;
            display: flex;
            padding: 15px 0;
            align-items: center;
            font-size: 13px; }
            .multiVideoLinkModal .videoContent .video-input-container .vid-header .controls span:hover {
              background: rgba(0, 0, 0, 0.09);
              cursor: pointer; }
            .multiVideoLinkModal .videoContent .video-input-container .vid-header .controls span > i,
            .multiVideoLinkModal .videoContent .video-input-container .vid-header .controls span > svg {
              margin: 0 4px; }
          .multiVideoLinkModal .videoContent .video-input-container .vid-header .controls .vidDownArrow svg {
            transform: rotate(180deg); }
      .multiVideoLinkModal .videoContent .video-input-container .video-box {
        padding: 20px; }
        .multiVideoLinkModal .videoContent .video-input-container .video-box input,
        .multiVideoLinkModal .videoContent .video-input-container .video-box textarea {
          font-size: 14px;
          color: #495057;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          padding: 8px; }
          .multiVideoLinkModal .videoContent .video-input-container .video-box input::placeholder,
          .multiVideoLinkModal .videoContent .video-input-container .video-box textarea::placeholder {
            color: #ced4da;
            font-style: italic; }
        .multiVideoLinkModal .videoContent .video-input-container .video-box .previewBox {
          justify-content: center; }
          .multiVideoLinkModal .videoContent .video-input-container .video-box .previewBox iframe,
          .multiVideoLinkModal .videoContent .video-input-container .video-box .previewBox video {
            box-shadow: none;
            display: flex;
            justify-content: center;
            margin: auto;
            height: 100%;
            width: 100%; }
        .multiVideoLinkModal .videoContent .video-input-container .video-box .row1,
        .multiVideoLinkModal .videoContent .video-input-container .video-box .row2 {
          display: flex;
          width: 100%;
          align-items: center; }
        .multiVideoLinkModal .videoContent .video-input-container .video-box .row2 {
          flex-direction: column; }
          .multiVideoLinkModal .videoContent .video-input-container .video-box .row2 textarea {
            width: 100%;
            margin-top: 10px; }
    .multiVideoLinkModal .videoContent .video-input {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .multiVideoLinkModal .videoContent .video-input .bin-icon {
        margin-left: 10px;
        cursor: pointer; }
      .multiVideoLinkModal .videoContent .video-input .invalid {
        border-color: #d7073a;
        box-shadow: none; }
      .multiVideoLinkModal .videoContent .video-input .serial-no {
        width: 30px;
        border: none;
        outline: none;
        cursor: inherit; }
        .multiVideoLinkModal .videoContent .video-input .serial-no:hover, .multiVideoLinkModal .videoContent .video-input .serial-no:focus {
          border: none; }
      .multiVideoLinkModal .videoContent .video-input .moveIcon {
        position: relative;
        width: 50px;
        height: 30px;
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .multiVideoLinkModal .videoContent .video-input .moveIcon .vidUpArrow {
          position: absolute;
          top: 35%;
          left: 0; }
          .multiVideoLinkModal .videoContent .video-input .moveIcon .vidUpArrow svg {
            fill: blue; }
        .multiVideoLinkModal .videoContent .video-input .moveIcon .vidDownArrow {
          position: absolute;
          top: 35%;
          left: 15px; }
          .multiVideoLinkModal .videoContent .video-input .moveIcon .vidDownArrow svg {
            transform: rotate(180deg); }
    .multiVideoLinkModal .videoContent .add-input {
      margin-top: 10px;
      padding: 10px;
      float: right;
      color: #2251ff;
      cursor: pointer;
      margin-right: 16px; }
    .multiVideoLinkModal .videoContent input {
      height: 40px;
      padding: 5px;
      margin-top: 10px;
      border-radius: 3px; }
  .multiVideoLinkModal .btn-section {
    margin: 20px 0; }

@media (max-width: 1100px) {
  .lopCustomSection .addCustomContent .contentItems div.selectorType2 {
    margin-left: 30px;
    font-size: 0.625rem; }
    .lopCustomSection .addCustomContent .contentItems div.selectorType2:first-child {
      margin-left: 0; } }

@media (max-width: 1024px) {
  .customSectionContent .contentHeader {
    padding: 0 0 8px 0; }
  .lopCustomSection .addCustomContent {
    padding: 35px 16px; }
    .lopCustomSection .addCustomContent .contentHeader {
      margin: 24px 0 40px 0; }
    .lopCustomSection .addCustomContent .contentItems {
      margin: 24px 0; }
      .lopCustomSection .addCustomContent .contentItems div.selectorType2 {
        margin-left: 20px;
        font-size: 0.625rem; }
        .lopCustomSection .addCustomContent .contentItems div.selectorType2 svg {
          width: 68px;
          height: 47px; }
    .lopCustomSection .addCustomContent .buttonContent {
      padding: 20px 0; } }

@media (max-width: 991px) {
  .customSectionContent .desc {
    font-size: 0.75rem; }
  .lopCustomSection .addCustomContent {
    padding: 35px 12px; }
    .lopCustomSection .addCustomContent .contentItems div {
      margin-left: 40px; }
      .lopCustomSection .addCustomContent .contentItems div.selectorType2 {
        margin-left: 16px; } }

@media (max-width: 768px) {
  .customSectionContent {
    padding: 45px 20px; } }

@media only screen and (max-width: 500px) and (max-width: 812px), only screen and (max-height: 320px) and (orientation: landscape) {
  .customSectionContent {
    padding: 25px 20px !important;
    min-height: inherit;
    padding-bottom: 46px !important; }
    .customSectionContent .contentHeader h2,
    .customSectionContent .contentHeader h3 {
      text-align: left;
      word-wrap: normal;
      text-overflow: unset;
      white-space: normal; }
    .customSectionContent .contentHeader {
      padding-left: 0 !important; }
    .customSectionContent .imageContainer,
    .customSectionContent .videoContainer,
    .customSectionContent .textContent {
      width: 100% !important;
      margin: 0 !important; }
    .customSectionContent .imageContainer .imageCollage {
      margin-left: 0 !important; }
    .customSectionContent .videoContainer .videoIframe {
      margin-left: 5%; }
    .customSectionContent .textContent {
      margin-bottom: 20px !important;
      padding: 0 20px; }
    .customSectionContent .borderDesc {
      display: none; }
    .customSectionContent .desc {
      max-height: 65px;
      height: auto; }
      .customSectionContent .desc p {
        margin-bottom: 4px; }
      .customSectionContent .desc ul {
        padding: 0 0 0 16px; }
        .customSectionContent .desc ul li {
          margin-bottom: 4px; }
    .customSectionContent .readMore {
      margin-top: 10px;
      font-size: 0.75rem; } }

@media (max-width: 440px) {
  .customSectionContent .contentHeader {
    padding-bottom: 4px; }
  .customSectionContent .textContent .borderDesc {
    height: 2px;
    margin-bottom: 8px; }
  .customSectionContent .desc {
    max-height: 200px; } }
