@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Regular';
  font-style: normal;
  src: url(../fonts/McKinseySans-Regular.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Regular';
  font-style: italic;
  src: url(../fonts/McKinseySans-Italic.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Regular';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/McKinseySans-Medium.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Medium';
  font-style: normal;
  src: url(../fonts/McKinseySans-Medium.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Medium';
  font-style: italic;
  src: url(../fonts/McKinseySans-MediumItalic.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Light';
  font-style: normal;
  src: url(../fonts/McKinseySans-Light.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Light';
  font-style: italic;
  src: url(../fonts/McKinseySans-LightItalic.woff); }

@font-face {
  font-display: swap;
  font-family: 'Bower Bold';
  font-style: normal;
  src: url(../fonts/Bower-Bold.woff); }

.ant-tabs-nav .ant-tabs-tab {
  font-family: 'McKinsey Sans Regular';
  font-size: 1rem;
  color: #333333;
  margin: 0 24px 0 0;
  padding: 12px 0; }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #2251ff; }

.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 400;
  color: #2251ff; }

.ant-tabs-ink-bar {
  background-color: #2251ff;
  height: 3px; }

.ant-btn {
  border-radius: 0; }
  .ant-btn:hover, .ant-btn:focus {
    border-color: #0d3adf;
    color: #0d3adf; }
  .ant-btn.ant-btn-primary:disabled span {
    color: rgba(0, 0, 0, 0.25); }
  .ant-btn.ant-btn-primary span {
    color: #ffffff; }
  .ant-btn.ant-btn-primary:hover {
    background-color: #0d3adf;
    color: #ffffff; }
  .ant-btn.ant-btn-primary .ant-btn-dangerous {
    background-color: #dc3545;
    border-color: #dc3545; }
    .ant-btn.ant-btn-primary .ant-btn-dangerous:hover, .ant-btn.ant-btn-primary .ant-btn-dangerous:focus {
      background-color: #c82333;
      border-color: #bd2130; }

.ant-input {
  border-radius: 0; }
  .ant-input.ant-input-lg {
    height: 42px; }

.ant-form-item label {
  margin-bottom: 0; }

.btn {
  border-radius: 0; }
  .btn.size-136 {
    min-width: 136px; }
  .btn.btn-margin {
    margin-right: 15px; }
