@import '../../Styles/variables';
@import '../../Styles/responsive.scss';

.createCst {
    h3 {
        font-size: 1.875rem;
        font-weight: 400;
        margin-bottom: 15px;

        svg {
            margin-right: 25px;
            cursor: pointer;
        }
    }

    .cstWrapper {
        border-radius: 6px;
        box-shadow: 2px 6px 20px -3px $gray-sixteen;
        background: $white;
        padding: 25px 40px;
        float: left;
        width: 100%;
        display: block;

        .form-group {
            float: left;
            width: 100%;
            margin-bottom: 25px;

            label {
                text-transform: uppercase;
                font-size: 0.625rem;
                color: #a7a7a7;
                line-height: 2;
            }

            input[type='text'] {
                border-radius: 4px;
                border: solid 1px $blue-twenty;
                font-size: 0.8125rem;
                padding: 8px 20px 10px;
                height: auto;
            }

            &.checkBoxAck {
                padding-left: 24px;
                margin-top: -15px;

                input {
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    left: 0;
                    cursor: pointer;
                }

                label {
                    display: block;
                    text-transform: none;
                    cursor: pointer;
                }
            }

            &.emailOuter {
                label {
                    width: 100%;
                    float: left;
                }

                input {
                    float: left;
                }

                button {
                    float: left;
                    background: none;
                    border: 0;
                    color: $blue-ninetheen;
                    font-size: 0.75rem;
                    line-height: 28px;
                    box-shadow: none !important;
                    text-transform: uppercase;
                    outline: none;
                }
            }

            &.dropOuter {
                label {
                    width: 100%;
                    float: left;
                }

                .dropdown {
                    display: inline-flex;
                    width: 100%;
                    & > button {
                        border-radius: 4px;
                        border: solid 1px $blue-twenty;
                        background-color: $white;
                        font-size: 0.8125rem;
                        color: $site-text-color;
                        min-width: 90px;
                        height: auto;
                        padding: 10px 20px;
                        text-align: left;
                        position: relative;
                        box-shadow: none !important;
                        outline: none;

                        &:after {
                            position: absolute;
                            right: 15px;
                            top: 12px;
                            width: 8px;
                            height: 9px;
                            transform: rotate(45deg);
                            border-right: 2px solid $site-blue;
                            border-bottom: 2px solid $site-blue;
                            border-top: 0;
                            border-left: 0;
                        }
                    }

                    & > div {
                        min-width: 90px;
                        margin-top: 0;
                        max-height: 200px;

                        & > button {
                            box-shadow: none !important;
                            outline: none;
                            &:hover {
                                color: $white;
                                background: $site-blue;
                            }
                        }
                    }

                    :last-child {
                        margin-left: 0px;
                        padding-left: 8px;
                    }
                }
            }
        }

        .chargeCode {
            display: block;
            float: left;
            width: 100%;
            position: relative;

            label {
                float: left;
                width: 100%;
            }

            .chargeCode-container {
                display: block;
                float: left;
                width: 100%;
                position: relative;
                input {
                    width: 100%;
                    float: left;
                }

                button,
                .validChargeCode {
                    clear: both;
                    position: absolute;
                    top: 1.4px;
                    left: 82%;
                    height: 90%;
                    width: 17.5%;
                    padding: 0;
                    font-size: inherit;
                    line-height: normal;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    background: #4a4a4a9c;
                    border: #c4c4c4;
                    &:focus {
                        box-shadow: none;
                    }
                }
                .validChargeCode {
                    color: #87d068;
                    background: transparent;
                    content: 'ok';
                    position: absolute;
                    z-index: 999;
                }
            }
        }

        .leftSection {
            float: left;
            width: 40%;
        }

        .rightSection {
            float: left;
            width: 60%;
            padding-left: 64px;

            .form-group {
                input[type='text'] {
                    width: 60%;
                }
            }

            .indicates {
                float: left;
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
                text-align: center;
                div {
                    float: left;
                    width: 10%;
                    font-size: 0.6875rem;
                    border-radius: 4px;
                }
                .new {
                    background: $blue-twenty;
                    margin-left: 2px;
                }
                .approved {
                    background: #87d068;
                    margin-left: 2px;
                }
                .pending {
                    background: #ffd591;
                    margin-left: 4px;
                }
                .denied {
                    background: #f50;
                    margin-left: 4px;
                }
            }

            .emailGroup {
                border-radius: 4px;
                border: 1px solid $blue-twenty;
                float: left;
                width: 100%;
                padding: 10px 20px;
                list-style-type: none;
                margin-top: 1px;

                li {
                    float: left;
                    border-radius: 5px;
                    background-color: $blue-twenty;
                    padding: 4px 8px;
                    font-size: 0.75rem;
                    margin: 0 6px 7px 0;
                    border: 2px solid $blue-twenty;

                    span {
                        opacity: 0.5;
                        color: #323c47;
                        margin-left: 6px;
                        cursor: pointer;
                    }
                }
                .denied {
                    border: 2px solid #f50;
                    background: #fff1f0;
                }
                .pending {
                    border: 2px solid #ffd591;
                    background: #fff7e6;
                }
                .approved {
                    border: 2px solid #87d068;
                    background: #f6ffed;
                }
            }
        }

        .buttonContent {
            float: left;
            width: 100%;
            text-align: center;

            button {
                background: $site-blue;
                border: 0;
                color: $white;
                font-size: 0.8125rem;
                text-transform: uppercase;
                box-shadow: none;
                outline: none;
                width: 77px;
                height: 32px;
            }
        }
    }
}

.cstTable {
    margin-top: 30px;

    .table-header,
    .data-row {
        display: flex;
        flex-direction: row;
    }

    .table-header {
        background-color: #dfe1e7;
        font-size: 0.75rem;
        font-weight: 500;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 0.6rem 24px;
        width: 100%;
    }
    .data-row {
        background-color: #fff;
        border-top: 1px solid #dee2e6;
        padding: 0.6rem 24px;
        font-size: 0.75rem;
    }
    .table-header,
    .data-row > {
        :nth-child(1) {
            text-align: left;
            width: 25%;
        }
        :nth-child(2) {
            width: 15%;
            text-align: left;
        }

        :nth-child(3) {
            width: 23%;
            text-align: left;
        }

        :nth-child(4) {
            width: 15%;
            text-align: left;
        }

        :nth-child(5) {
            width: 17%;
            text-align: left;
        }
        :nth-child(6) {
            width: 5%;
            text-align: left;
        }
    }

    .table-header {
        :first-child {
            cursor: pointer;
        }
    }
}

.cstList {
    h1 {
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .upperHeader {
        line-height: 39px;
        input {
            border-radius: 4px;
            border: solid 1px $blue-twenty;
            font-size: 0.8125rem;
            padding: 8px 20px 10px;
            height: auto;
            float: left;
            width: 30%;
        }

        .cstCount {
            margin-left: 28px;
            font-size: 0.875rem;
            color: $black;
        }

        .addCst {
            float: right;
            cursor: pointer;
            font-size: 0.75rem;
            color: $blue-ninetheen;
            text-transform: uppercase;

            svg {
                margin-right: 8px;
            }
        }
    }
}
@media (max-width: $breakpointDesktop) {
    .createCst {
        .cstWrapper {
            padding: 45px;

            .form-group {
                input[type='text'] {
                    width: 60%;
                }

                .chargeCode-container {
                    width: 60%;
                    input {
                        width: 100%;
                    }
                    button {
                        position: absolute;
                        top: 3px;
                        left: 82%;
                        height: 85%;
                        width: 17.5%;
                        padding: 0;
                        font-size: inherit;
                        line-height: normal;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
            .rightSection,
            .leftSection {
                width: 100%;
                padding-left: 0;
            }
        }
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .header-text {
        display: flex;
        flex-direction: column;

        input {
            width: 100% !important;
        }
        .cstCount {
            margin-left: 8px !important;
        }
    }
    .cstTable {
        .card-data {
            display: block;
            border-radius: 6px;
            box-shadow: 0 0 15px 0 rgba(48, 110, 253, 0.15);
            border: solid 0.2px #d2d3d3;
            margin-bottom: 30px;
            padding: 20px;
            width: 100%;
            float: left;
            background-color: #fff;
            .name-header,
            .chargeCode-header,
            .contact-header,
            .duration-header {
                display: block;
                float: left;
                :first-child {
                    font-size: 0.625rem;
                    line-height: 19px;
                }
            }
            .name-header {
                margin-bottom: 10px;
                width: 100%;
                :nth-child(2) {
                    font-size: 0.9375rem;
                    line-height: 29px;
                    font-weight: 500;
                }
            }
            .contact-header {
                margin-bottom: 10px;
                width: 100%;
                :nth-child(2) {
                    font-size: 0.75rem;
                    line-height: 29px;
                    font-weight: 500;
                }
            }
            .chargeCode-header {
                width: 50%;
                :nth-child(2) {
                    font-size: 0.75rem;
                    line-height: 29px;
                    font-weight: 500;
                }
            }
            .duration-header {
                width: 50%;
                :nth-child(2) {
                    font-size: 0.75rem;
                    line-height: 29px;
                    font-weight: 500;
                }
            }
        }
    }
}
