.forgot-pass {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 450px;
  padding: 60px 100px;
  border-radius: 10px;
  text-align: center;
  background-color: #ffffff;
  color: #2d3649;
  margin: auto;
  border: 1px solid #dddddd;
  box-shadow: 5px 5px 10px 0 rgba(228, 228, 228, 0.6); }
  .forgot-pass .heading {
    font-weight: 500;
    line-height: 2.5em; }
  .forgot-pass .sub-heading {
    text-align: center;
    color: #666666;
    font-size: 0.8125rem;
    margin-top: 30px;
    margin-bottom: 30px; }
  .forgot-pass label {
    float: left;
    margin-left: 10px; }
  .forgot-pass input {
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
    width: 100%; }
    .forgot-pass input:focus {
      box-shadow: none; }
    .forgot-pass input::-webkit-input-placeholder {
      color: #cccccc; }
  .forgot-pass .inlining {
    display: inline-flex;
    width: 100%; }
  .forgot-pass button {
    border-radius: 20px;
    min-width: 100px;
    padding: 4px;
    margin-left: 10px; }
    .forgot-pass button.btn-primary {
      background-color: #4dbdfe;
      border-color: #4dbdfe;
      color: #ffffff; }
    .forgot-pass button.btn-outline-info, .forgot-pass button.btn-outline-info:focus {
      color: #4dbdfe;
      border-color: #4dbdfe;
      background-color: #ffffff; }
    .forgot-pass button.btn-outline-info:active {
      color: #4dbdfe !important;
      border-color: #4dbdfe !important;
      background-color: #ffffff !important; }
  .forgot-pass .btn-div {
    margin-top: 30px; }
  .forgot-pass .emptyDiv {
    background-color: #4dbdfe;
    height: 4px;
    width: 60px;
    margin: 0 auto;
    border-radius: 10px; }
