//documents Admin Page  Start
@import '../../Styles/variables';

.admin-container {
    background-color: $white-second;
    padding-top: 24px;
    .react-tabs > div {
        margin-top: 0;
    }

    .tableFirstDiv {
        padding-top: 18px;
    }
    .react-tabs {
        ul {
            display: inline-block;
            li {
                &.react-tabs__tab--selected {
                    background-color: transparent;
                }
            }
        }
    }
    .form-container {
        height: 100%;
        display: inline-flex;
        width: 100%;
        margin-top: 20px;
        form {
            input.invalidInput {
                border: 1px solid $red;
            }
            padding: 30px;
            background-color: $white;

            .fileName {
                margin: 20px 0;
            }
            box-shadow: 2px 6px 20px -3px #d2d2d2;

            .form-group {
                margin: 40px 0;
                text-align: center;
                @media (max-width: 1300px) and (min-width: 1100px) {
                    margin: 25px 0;
                }
                label {
                    float: left;
                }

                input {
                    width: 100%;
                }

                button {
                    font-weight: 200;
                    margin-top: 20px;
                }
            }

            .docName,
            .fileName {
                margin-right: 10px;
                padding: 10px;
            }
        }

        #uploadDocForm {
            width: 60%;

            .css-1492t68,
            ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: $gray-light;
                font-style: italic;
            }
            height: 90;
            label {
                font-size: 1rem;
                font-family: 'McKinsey Sans Regular';
                color: $black;
            }
        }
    }
}
label {
    font-size: 1rem;
    font-family: 'McKinsey Sans Regular';
    color: $black;
}
.uploadDoc-container {
    .module-header {
        margin-top: 0;
        margin-bottom: 6px;
        span {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.2;
        }
    }
}
.createuser-ctrl {
    margin-top: 25px;
}
#uploadFileDiv {
    margin-left: 30px;
    width: calc(40% - 30px);
    background-color: $white;
    padding: 20px;
    box-shadow: 2px 6px 20px -3px #d2d2d2;
    margin-bottom: 0;

    .dropzone {
        width: 100%;
        height: 100px;
        color: $gray-lighter;
        border-width: 1px;
        border-color: $blue-main;
        border-style: dashed;
        border-radius: 5px;
        text-align: center;
        padding-top: 25px;
        padding-left: 15%;
        padding-right: 15%;
        font-family: 'McKinsey Sans Regular';
        @media (max-width: 1200px) {
            position: relative;
            line-height: normal;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 0.75rem;
        }
        svg {
            margin-right: 10px;
            display: block;
            float: left;
        }
    }

    > .removeFile {
        height: 50px;
        display: flex;
        cursor: pointer;

        > svg {
            margin: auto;
            height: 30px;

            width: 30px;
            fill: $gray-lighter;

            &:hover {
                fill: $blue-sixth;
            }
        }
    }
}

.leftLogoCss {
    padding: 0;
}

.extendArrow {
    transform: rotate(-180deg) scale(-0.7);
    margin-top: -15px !important;
    display: inline-block;
}

//documents Admin Page  End

//createUser
.removeFile {
    margin-left: 45%;
}

#uploadFileDivForUser {
    background-color: $white;
    width: 100px;
    height: 100px;

    .dropzone {
        width: 100%;
        height: 100px;
        color: $gray-lighter;
        border-width: 1px;
        border-color: $blue-main;
        border-style: dashed;
        border-radius: 0;
        text-align: center;
        padding-top: 25px;
        font-weight: 500;

        img {
            border-radius: 0;
        }
    }

    > .removeFile {
        height: 50px;
        display: flex;
        cursor: pointer;

        > svg {
            margin: auto;
            height: 30px;
            width: 30px;
            fill: $gray-lighter;

            &:hover {
                fill: $blue-sixth;
            }
        }
    }
}

.fullFormWidth {
    width: 100%;
    display: inline-flex;
    background-color: none;
}

.firstDivCreateUser {
    width: 60%;
    padding: 3%;
    border-radius: 5px;
    margin-right: 22px;
    background-color: $white;
    box-shadow: 12px 12px 6px 0 rgba($color: $white-fifteenth, $alpha: 0.6);

    &.fullWidth {
        width: 100%;

        .parentEmail {
            & > div {
                display: block !important;

                input {
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 12px + 2px);
                    position: relative;
                    padding: 6px 12px;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: $gray-bluish;
                    background-clip: padding-box;
                    border: 1px solid $white-seventh;
                    border-radius: 4px;
                    text-align: left;
                }

                & > div {
                    z-index: 10;
                    max-height: 220px !important;
                    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 12px !important;
                    padding: 0 !important;
                    margin-top: 2px;
                    background: $white !important;

                    & > div {
                        padding: 6px 8px;
                        font-size: 0.875rem;
                        cursor: pointer;

                        &:hover {
                            background: $white-sixteen;
                        }
                    }
                }
            }
        }

        .select-validation {
            background: #e9ecef;
        }

        .dropdown {
            width: 100%;
            display: block;

            .btn {
                display: block;
                width: 100%;
                height: calc(1.5em + 12px + 2px);
                position: relative;
                padding: 6px 12px;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: $gray-bluish;
                background-color: $white;
                background-clip: padding-box;
                border: 1px solid $white-seventh;
                border-radius: 4px;
                text-align: left;

                &:focus {
                    box-shadow: 0 0 0 3.2px rgba(0, 123, 255, 0.25);
                    border-color: $blue-validation;
                }

                img {
                    position: absolute;
                    right: 0;
                    top: -4px;
                    width: 36px;
                    height: 36px;
                }
            }

            .dropdown-menu {
                padding: 0;
                margin: 0;
            }
        }
    }

    .btnuser {
        text-align: center;
        .createUserBtn {
            margin: 0;
            max-width: 250px;
        }
    }
}

.secDivCreateUser {
    width: 40%;
    padding: 3%;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 12px 12px 6px 0 rgba($color: $white-fifteenth, $alpha: 0.6);
}

#createForm .form-group {
    margin-bottom: 32px;
}

.createUserBtn {
    margin-left: 100px;
    padding: 10px;
    width: 50%;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: $red-second;
}

.has-error .form-control {
    border-color: $red-second;

    box-shadow: inset 0 1px 1px rgba($black, 0.075);
}

.has-error .form-control:focus {
    border-color: $red-fifth;
    box-shadow: inset 0 1px 1px rgba($black, 0.075), 0 0 6px $red-third;
}

.has-error .input-group-addon {
    color: $red-second;
    border-color: $red-second;
    background-color: $white-fifth;
}

.has-error .multiselect-container > .active .checkbox {
    color: $white;
}

.has-error .multiselect {
    border-color: $red-second;
    color: $red-second;
}

.has-error .form-control:focus {
    box-shadow: none;
}

.has-error .btn-group.open .multiselect.btn,
.has-error .multiselect.btn-default:active {
    color: $red-second;
    border-color: $red-second;
}

.has-error .form-control-feedback {
    color: $red-second;
}
//createuser end..

.addTag {
    display: inline-flex;
    width: 100%;

    > div {
        width: 100%;
    }

    .addTagBtnUser {
        position: relative;
        background: transparent;
        color: $blue;
        border: none;
        cursor: pointer;
    }

    .addTagBtn {
        position: relative;

        margin-left: -110px;
        background: transparent;
        color: $blue;
        border: none;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}

.getBack {
    display: block;
    float: left;
    margin-top: 3px;
    @media (max-width: 1300px) and (min-width: 1100px) {
        margin-top: 2px;
    }
    svg {
        cursor: pointer;
        margin-right: 10px;
        float: left;

        &:hover {
            text-decoration: underline;
        }
    }
}

.admin-dashboardcard {
    padding: 15px;
    width: 33%;
    margin-right: 30px;
    border-radius: 6px;
    box-shadow: 2px 6px 10px 0 rgba($color: $white-fifteenth, $alpha: 0.6);
    background-color: $white;
    min-height: 98px;
    &:last-child {
        margin-right: 0;
    }
    @media (max-width: 1300px) and (min-width: 1100px) {
        min-height: 82px;
    }
    .card-headings {
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
    }
}

.card-container {
    display: inline-flex;
    width: 100%;
}

.top-heading-admin {
    font-family: 'McKinsey Sans Medium';
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: $black;
}

.lower-heading-admin {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: 'McKinsey Sans Medium';
    color: $black;
}

.blue-bar-admin {
    height: 3.5px;
    background-color: #00a9f4;
    width: 40px;
    margin-top: 0;
    margin-bottom: 23px;
    border-radius: 0;
}

.card-headings {
    font-size: 1rem;
    font-weight: 500;
}

.total-user-count {
    font-size: 1.75rem;
    font-family: 'Bower Bold';
}

.emailmodal {
    max-width: 1100px;
    padding: 15px;
    .btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}
.ant-card-head-title {
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
}

.copiedClip {
    background: $site-blue;
    color: $white;
    opacity: 0.8;
    position: fixed;
    top: -40px;
    right: 100px;
    padding: 4px 20px;
    border-radius: 20px;
    bottom: 50px;
    -webkit-transition-property: top;
    -webkit-transition-duration: 0.5s;
    height: 30px;
    z-index: 1000;

    &.showCopy {
        top: 105px;
    }
}

.ant-card-body {
    display: block;
    float: left;
    width: 100%;
    height: 400px;
    scroll-behavior: auto;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-bottom: 10px;
    padding: 15px;
    background: #f5f5f5;
    > div {
        width: 32%;
        float: left;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.23);
        color: rgba(0, 0, 0, 0.87);
        cursor: default;
        height: 32px;
        display: inline-flex;
        font-size: 0.8125rem;
        box-sizing: border-box;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        white-space: nowrap;
        /* border-radius: 16px; */
        vertical-align: middle;
        justify-content: center;
        text-decoration: none;
        margin: 4px;
        &:hover {
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            background-color: #fff;
        }
    }
}

.total-user-count.total {
    color: $blue-fourth;
}

.total-user-count.new {
    color: $red-fourth;
}

.total-user-count span {
    opacity: 0.5;
    font-size: 1rem;
    font-family: 'McKinsey Sans Medium';
    color: $black-second;
}
.scrollauto {
    width: 100%;
    overflow: auto;
}
.customAdminSessionTable {
    th.desc:after {
        color: $blue;
        content: '\2B07';
        float: right;
    }

    th.asc:after {
        color: $blue;
        content: '\2B06';
        float: right;
    }

    th {
        cursor: pointer;
        height: 48px;
        background-color: $white-third;
        color: $black-seventh;
        font-size: 0.875rem;
        font-weight: 500;
    }

    td {
        &:nth-child(1) .name-section {
            display: inline-flex;
            width: 100%;
        }
    }

    td,
    th {
        &:nth-child(1) {
            text-align: left;
            width: 25%;
            vertical-align: middle;
        }

        &:nth-child(2) {
            width: 20%;
            text-align: left;
            vertical-align: middle;
        }

        &:nth-child(3) {
            width: 20%;
            text-align: left;
            vertical-align: middle;
        }

        &:nth-child(4) {
            width: 20%;
            text-align: left;
            vertical-align: middle;
        }

        &:nth-child(5) {
            width: 20%;
            text-align: left;
            vertical-align: middle;
        }
        &:nth-child(6) {
            width: 10%;
            text-align: center;
            vertical-align: middle;
            svg {
                height: 18px;
                width: 18px;
            }
        }
    }

    tr {
        background-color: $white;

        &:hover {
            background-color: rgba($black, 0.04) !important;
        }
    }

    .clickable a {
        color: $blue-fifth;
    }
}

.user-name {
    width: 100% !important;
    font-size: 1rem;
}

.emailCss {
    opacity: 0.5;
    font-size: 0.75rem;
    line-height: normal;
    color: $black-second;
}

.rangeslider-horizontal .rangeslider__fill {
    /* opacity: 0.5 !important; */
    width: 100% !important;
    border-radius: 6px !important;
    background-color: $gray-seventeen !important;
    box-shadow: initial !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
    background-color: $red-fourth !important;
}

.rangeslider__labels .rangeslider__label-item {
    font-size: 0.875rem;
    color: $blue-fourth;
}

.tabletxt {
    font-size: 0.875rem;
    color: $black-second;
}

.userImage {
    margin-right: 10px;
}

.rangeslider-horizontal .rangeslider__handle {
    outline: none !important;
    width: 12px !important;
    height: 12px !important;
    outline: none;
}

.rangeslider .rangeslider__handle {
    /* border: 8px solid $white !important;
    cursor: pointer; */
    background-color: $site-blue-new !important;
    box-shadow: none !important;
    &:after {
        display: none;
    }
}

.rangeslider-horizontal {
    height: 4px !important;
    width: 80%;
}

.rangeslider-horizontal .rangeslider__handle:after {
    width: 12px !important;
    height: 13px !important;
    top: -6px !important;
    left: -6px !important;
}

.rangeslider__handle-label {
    position: absolute;
    font-size: 0.9375rem;
    color: $red-fourth;
    top: -25px;
}
.admin-action-td {
    & > span {
        display: block;
        white-space: nowrap;
    }
}
.block-unblock-text {
    margin-left: 10px;
    cursor: pointer;
    margin-top: 4px;
    vertical-align: middle;
}

.tableSecDiv {
    float: right;
}

.tableSecDivDashboard {
    //float: left;
}

.admin-session-container {
    width: 100%;
    display: inline-flex;
    .customAdminSessionTable {
        margin-top: 10px;
    }
}
.round-shape {
    border-radius: 100%;
    overflow: hidden;
}
.admin-session-left {
    width: 80%;
}

.admin-session-right {
    width: 30%;
    background-color: $white;
    margin-left: 2%;
    border: 1px solid $white-fourth;
    position: fixed;
    right: -10%;
    top: 70px;
    height: 100%;
    overflow: scroll;
    transition: all 0.5s ease;
    padding-bottom: 55px;
}

.admin-session-tablet {
    width: 100%;
    background-color: $white;
    border: 1px solid $white-fourth;
    margin-bottom: 20px;
    margin-top: -10px;
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 1024px) {
    .firstDivCreateUser {
        width: 100%;
    }

    .secDivCreateUser {
        width: 100%;
    }

    .fullFormWidth {
        display: inline-block;
    }

    .btnuser {
        text-align: center;
    }

    .createUserBtn {
        margin-left: 0;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .admin-session-left {
        width: 100%;
    }

    .customAdminSessionTable td:nth-child(1),
    .customAdminSessionTable th:nth-child(1) {
        text-align: left;
        width: 20%;
        vertical-align: middle;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .admin-session-left {
        width: 100%;
    }
    .customAdminSessionTable th {
        font-size: 0.75rem;
    }
    .customAdminSessionTable td {
        font-size: 0.75rem;
        white-space: nowrap;
    }
    .customAdminSessionTable td:nth-child(1),
    .customAdminSessionTable th:nth-child(1) {
        text-align: left;
        width: 20%;
        vertical-align: middle;
    }

    #uploadFileDivForDeliver.images {
        flex-direction: column !important;

        .image-drop-container .dropzone {
            width: 130px;
        }
    }

    .block-unblock-text {
        margin-left: 2px;
        cursor: pointer;
        margin-top: 4px;
        font-size: 0.875rem;
    }

    .customAdminSessionTable td:nth-child(2),
    .customAdminSessionTable th:nth-child(2) {
        width: 7%;
        text-align: left;
        vertical-align: middle;
    }

    .customAdminSessionTable td:nth-child(3),
    .customAdminSessionTable th:nth-child(3) {
        width: 11%;
        text-align: left;
        vertical-align: middle;
    }
}

.lower-section {
    .blue-bar-admin {
        margin-bottom: 5px;
    }

    .admin-settings-heading {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
    }

    .user-history-heading {
        width: 50%;
    }

    .download-btn {
        margin-top: 10px;
    }
}

.single-line-input {
    border-left: none;
    border-right: none;
    width: 33%;
    border-top: none;
    border-radius: 0;
    border-color: $blue-seventh;
    padding: 0;
    height: 25px;

    &:focus {
        box-shadow: none;
    }
}

.small-heading-limit {
    color: $gray-bluish;
    font-size: 0.75rem;
    line-height: normal;
}

.label-role {
    width: 30%;
    margin-top: 12px;
    margin-left: 30%;
}

.label-value-input {
    width: 100%;
    display: inline-flex;
}

.set-limit-header {
    text-align: center;
    color: $black;
    font-size: 1.5rem;
    font-family: 'McKinsey Sans Medium';
}

.download-inputs {
    min-height: 150px;
}

.set-limit-modal {
    min-width: 320px !important;
    max-width: 580px !important;
    width: 95%;
    padding: 30px 45px;
}

.loader-alignment {
    height: 150px;
    position: relative;

    .loader-overlay {
        position: absolute;
    }
}

.block-unblock-click {
    cursor: pointer;
}

.pencil-format {
    margin-top: 16px;
    width: 15px;
    height: 15px;
}

.searchInputBoxDashboard {
    width: 100%;
    // padding-top: 14px;
    .searchInputBox {
        width: 100%;
    }
}
.adduser-icon:hover {
    circle {
        stroke: #348ff1;
    }
    text {
        stroke: #348ff1;
        opacity: 1;
    }
}
.searchBoxContainerDashboard {
    display: inline-flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
}

.showingRecordsDashboard {
    // margin-left: 48%;
    // padding-top: 46px;
    font-size: 0.75rem;
    white-space: nowrap;
    margin-left: 15px;
}

.workiInprogress {
    font-size: 1.25rem;
    text-align: center;
}

.heading-class {
    text-align: center;
}

.img-svg-noImg {
    padding: 10px;
}

.user-images {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.send-mail {
    margin-top: 10px;
    margin-right: 10px;
}
.limit-buttons {
    text-align: center;
    padding: 10px;
}

.cancel-button {
    margin: 10px;
    background: none;
    border: none;
}

#uploadFileDivForUser {
    .dropzone {
        width: 100%;
        color: $gray-lighter;
        border-style: dashed;
        border-radius: 0;
        text-align: center;
        font-weight: 500;
        border-width: 1.4px;
        border-color: $site-blue;
        cursor: pointer;
        height: 100px;
        padding-top: 0;

        img {
            object-fit: fill;
        }
    }

    .browse-icon {
        margin-top: 20px;
    }

    > .removeFile {
        height: 20px;
        display: flex;
        cursor: pointer;
        margin-bottom: 10px;

        > svg {
            margin: auto;
            height: 30px;
            width: 30px;
            fill: #666;

            &:hover {
                fill: #0056b3;
            }
        }
    }
}

.modal-custom.create-user-modal {
    height: auto;
    width: 300px;
    min-height: auto;
    min-width: 300px;
    .editable-item-modal {
        &.full-image {
            width: 100%;
            height: auto;
            padding: 0;
            .ReactCrop {
                padding: 5px;
                background-color: $white;
                width: 100%;
                max-width: 100%;
            }
            .ReactCrop__image {
                /* width: 300px; */
                height: auto !important;
                max-height: initial;
            }
            .image-container {
                width: 100%;
                height: auto;
                background-color: rgba(5, 28, 44, 0.1);
                background-clip: content-box;
            }
            .iconSpan {
                height: auto;
                svg {
                    width: auto;
                    height: auto;
                }
            }
            .overlay-icons {
                background-color: rgba(255, 255, 255, 0.8);
                right: 6px;
                top: 6px;
                span {
                    margin: 0;
                    border-right: solid 1px #ccc;
                    &:last-child {
                        border-right: none;
                    }
                    svg {
                        width: 40px;
                        height: 40px;
                    }
                    &.closeWrapper {
                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
        form {
            height: 100%;
            width: 100%;
        }

        img,
        span .img-span {
            height: 100%;
            width: 100%;
        }

        .iconSpan {
            height: 270px;
            width: 270px;
        }
    }
    &.deliver-modal {
        width: 500px;
        height: auto;
        .editable-item-modal {
            img {
                height: auto !important;
                width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
    .modal-custom.create-user-modal {
        margin-top: 35%;
    }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
    .modal-custom.create-user-modal {
        margin-top: 5%;
    }
}

.action-icon {
    cursor: pointer;
}

.cst-input {
    width: 100%;
    border: 1px solid $gray-second;
    height: 37px;
    border-radius: 3px;
    color: hsl(0, 0%, 50%);
    padding: 10px;
}

.rectangle {
    width: 43px;
    height: 55px;
    border-radius: 23px;
    box-shadow: 0 0 15px 0 rgba(0, 121, 255, 0.15);
    border: solid 2px var(--white);
    background-color: var(--white);
    position: fixed;
    right: 0;
    top: 40%;
    z-index: 999;
}

.chevron:hover {
    cursor: pointer;
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 1em;
    left: 0.75em !important;
    position: relative;
    top: 1.25em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 1em;
    color: #007bff;
}

.chevron.right:before {
    left: 0;
    transform: rotate(45deg);
}

.close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 1em;
    height: 1em;
    opacity: 1;
}

.close:hover {
    cursor: pointer;
}

.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 4px;
    background-color: #007bff;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

.open-right-panel {
    right: 0;
}

.deleteUser {
    cursor: pointer;
}

.visitors {
    margin-right: 10px;
}

.active-user-modal {
    width: 950px;
    .active-user-heading {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        color: #000;
    }
    .styles_closeButton__20ID4 {
        top: 7px !important;
        right: 7px !important;
    }
    .tab-name {
        width: 100%;
        font-size: 0.875rem;
        font-weight: 500;
        color: #000;
        margin-top: 40px;
        border-bottom: 1px solid #cdcdcd;
        flex-wrap: wrap;
        div {
            text-align: center;
            flex: 1 1 auto;
            margin-left: 30px;
            margin-right: 30px;
            font-size: 1rem;
            & > div:not(.bottom-line) {
                padding-bottom: 10px;
            }
        }
        .bottom-line {
            margin-top: 0;
            border-radius: 0;
            height: 3.5px;
            background-color: $site-blue;
        }
    }
    // .tab-user {
    //     text-align: right;
    //     width: 33.3%;
    //     padding-right: 20px;
    // }
    // .tab-page {
    //     text-align: left;
    //     width: 33.3%;
    //     padding-left: 20px;
    // }
    // .tab-trends {
    //     text-align: left;
    //     width: 33.3%;
    //     padding-left: 20px;
    // }

    .selected {
        font-size: 1rem;
        color: $site-blue;
    }
    .toggle {
        .btn {
            height: 28px;
            padding: 7px 10px;
            border: none;
            font-size: 0.6875rem;
            font-weight: bold;
            text-align: center;
            width: 120px;
        }
    }
    .btn-left {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .btn-right {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        color: rgba(37, 37, 37, 0.85);
    }
    .btn-center {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .toggle {
        border: 1px solid #d4d4d4;
        border-radius: 15px;
        margin-bottom: 20px;
        margin: 28px auto;
    }
    .selected-time {
        background-color: $site-blue;
        color: #fff;
    }
    &.full-page {
        width: 100%;
        border-bottom: none;
        display: inline-block !important;
        .dd-user {
            flex-grow: 5;
            max-width: 274px;
            .form-group .form-control {
                height: 34px;
                margin-top: 3px;
                width: 100%;
            }
        }
        .justify-content-center {
            align-items: center;
            flex-grow: 9;
            justify-content: flex-end !important;
        }
        .toggle {
            margin: 28px 25px 28px 0;
            border-radius: 5px;
            @media (max-width: 767px) {
                width: initial;
                margin-right: 0;
            }
            .btn {
                font-size: 0.875rem;
                font-weight: 500;
                height: 33px;
                @media (max-width: 767px) {
                    width: initial;
                    min-width: 100px;
                }
                &.btn-left {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
                &.btn-right {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                &.btn-center {
                    border-radius: 0;
                }
            }
        }
        .flex-col-sm {
            @media (max-width: 767px) {
                flex-direction: column;
                position: relative;
            }
            .dd-user {
                @media (max-width: 767px) {
                    max-width: 45%;
                }
                &:empty {
                    @media (max-width: 767px) {
                        display: none;
                    }
                    & + .justify-content-center {
                        @media (max-width: 767px) {
                            margin-bottom: 15px;
                        }
                    }
                }
                &:not(:empty) {
                    & + .justify-content-center {
                        @media (max-width: 767px) {
                            position: absolute;
                            right: 0;
                            bottom: 14px;
                        }
                    }
                }
            }
            .btn {
                &:not(.btn-outline-primary) {
                    @media (max-width: 767px) {
                        width: 33.33%;
                    }
                }
            }
        }
        .tab-name {
            display: inline-block !important;
            width: auto;
            margin-bottom: 20px;
            div.cursor {
                flex: initial;
                display: inline-block;
                margin-left: 0;
                padding-left: 0;
                padding-right: 0;
                div {
                    margin: 0;
                    font-size: 1rem;
                }
            }
        }
    }
}

.active-user-list {
    width: 100%;
    overflow: auto;
    .custom-table {
        max-width: 600px;
        tbody {
            th {
                background-color: #fff !important;
            }
        }
        th {
            font-weight: $font-weight-label;
            cursor: pointer;
            &:nth-child(6),
            &:nth-child(7) {
                cursor: initial;
            }
        }

        td {
            word-break: break-word;
        }

        td > .company-name {
            font-weight: $font-weight-label;
        }

        td.contact {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            & > span.name-info {
                display: flex;
                flex-direction: column;
            }

            & > .action-icons {
                display: flex;
                flex-direction: row;

                span {
                    margin: 2px;

                    &:first-of-type {
                        margin-right: 10px;
                    }
                }

                span,
                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        td,
        th {
            text-align: left;
            width: 10%;
            font-size: 0.75rem;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4) {
                width: 10%;
            }
            &:nth-child(1) {
                text-align: left;
                text-transform: capitalize;
            }
            &:nth-child(2) {
                text-align: center;
            }
            &:nth-child(3) {
                width: 6%;
            }

            &:nth-child(4) {
                width: 7%;
            }

            &:nth-child(5) {
                width: 14%;
                text-align: center;
            }

            &:nth-child(6) {
                width: 15%;
                text-align: center;
            }

            &:nth-child(8) {
                width: 1%;
            }

            &:nth-child(9) {
                width: 1%;
            }
        }
        &.user-table {
            margin-bottom: 16px;
            color: #212529;
            margin: 0;
            max-width: 500px;
        }
    }
}
.dd-user {
    .form-group {
        label {
            font-size: 8px;
        }
        .form-control {
            font-size: 0.75rem;
            width: 150px;
        }
    }
}
.v-center-parent {
    position: relative;
}
.v-center-relative {
    position: relative;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.form-control {
    border-radius: 0;
    height: 42px;
}

.currentPath {
    font-size: 0.875rem;
    color: $black;
    font-family: 'McKinsey Sans Light';
    opacity: 1;
    @media (max-width: 1200px) {
        font-size: 0.75rem;
    }
}
/* .btn-container {
     .btn {
        min-width: 120px;
        height: 40px;
        font-family: 'McKinsey Sans Regular';
        font-size: 1rem;
        &.btn-outline-primary {
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.65);
            &:hover {
                border-color: $site-blue-new;
                color: $site-blue-new;
                background-color: transparent;
            }
        }
    }
} */
.css-bg1rzq-control {
    border-radius: 0 !important;
    min-height: 42px;
}
