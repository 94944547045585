.firstLogindiv {
  width: 45%;
  height: 439.4px;
  border-radius: 4px;
  padding: 3%;
  background-color: #ffffff; }

.secondLogindiv {
  border-radius: 5px;
  position: relative; }

.textOnImage {
  position: absolute;
  bottom: 45px;
  padding-left: 5%; }

.login-container {
  width: 100%;
  display: inline-flex;
  margin: 0 auto; }
  .login-container button {
    text-align: center; }

.signIn {
  font-size: 1.5rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 3;
  color: #354052; }

.loginLogo {
  margin-top: -22%; }

.inputlabels {
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #7f8fa4; }

.inputfields {
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #dfe3e9;
  padding: 6.5%; }

.signinBtn {
  width: 100%;
  padding: 4%;
  margin-top: 16px;
  margin-bottom: 10px; }

.buttonVal {
  font-size: 0.875rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  text-align: center;
  color: #ffffff; }

.spacetobtn {
  margin-bottom: 30px; }

.loginImg {
  width: 100%;
  height: 439.4px;
  opacity: 1;
  background: url(../../Assets/McKinseyBackground-01.svg) no-repeat;
  background-size: cover; }

.borderCss {
  width: 100%;
  height: 1px;
  background-color: #e6eaee; }

.forgotPassword {
  font-size: 0.875rem;
  text-align: center;
  margin-top: 20px; }
  .forgotPassword a {
    color: #000000; }

.welcomeDigitalOuter {
  font-size: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #ffffff; }

.welcomeDigitalInner {
  font-size: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #ffffff;
  font-weight: bold; }

.mckinseyMergerMgmt {
  opacity: 0.9;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #ffffff; }

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .login-container {
    flex-direction: column-reverse; }
    .login-container .signIn {
      line-height: 1; }
    .login-container .firstLogindiv {
      width: 100%;
      margin-bottom: 20px;
      height: 100%; }
    .login-container .textOnImage {
      margin: 45px auto 0;
      text-align: center;
      position: relative;
      bottom: inherit; }
    .login-container .loginImg {
      margin-top: 5%;
      background-size: 100%;
      height: 150px; } }

.login-ctrl {
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999; }
  .login-ctrl .loginbg {
    background-repeat: no-repeat;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: auto 100%; }
    @media (max-width: 1200px) {
      .login-ctrl .loginbg {
        background-position: -185px; } }
    @media (max-width: 900px) {
      .login-ctrl .loginbg {
        background-position: 73% 0; } }
    @media (width: 375px) and (height: 812px) {
      .login-ctrl .loginbg {
        background-position: 63% 0; } }
  .login-ctrl .login-text-ctr {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 48vw;
    width: 500px; }
    @media (max-width: 900px) {
      .login-ctrl .login-text-ctr {
        left: 40vw; } }
    @media (max-width: 767px) {
      .login-ctrl .login-text-ctr {
        left: 31vw;
        width: 247px; } }
    .login-ctrl .login-text-ctr .btn-primary {
      width: 330px; }
      @media (max-width: 767px) {
        .login-ctrl .login-text-ctr .btn-primary {
          width: 227px;
          font-size: 13px; } }
    .login-ctrl .login-text-ctr .forgotpass {
      margin-top: 25px; }
    .login-ctrl .login-text-ctr h1 {
      font-family: 'Bower Bold';
      font-size: 60px;
      margin-bottom: 65px; }
      @media (max-width: 1300px) {
        .login-ctrl .login-text-ctr h1 {
          font-size: 50px; } }
      @media (max-width: 767px) {
        .login-ctrl .login-text-ctr h1 {
          font-size: 30px; } }
