@import url(~bootstrap/dist/css/bootstrap.min.css);
@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Regular';
  font-style: normal;
  src: url(../fonts/McKinseySans-Regular.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Regular';
  font-style: italic;
  src: url(../fonts/McKinseySans-Italic.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Regular';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/McKinseySans-Medium.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Medium';
  font-style: normal;
  src: url(../fonts/McKinseySans-Medium.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Medium';
  font-style: italic;
  src: url(../fonts/McKinseySans-MediumItalic.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Light';
  font-style: normal;
  src: url(../fonts/McKinseySans-Light.woff); }

@font-face {
  font-display: swap;
  font-family: 'McKinsey Sans Light';
  font-style: italic;
  src: url(../fonts/McKinseySans-LightItalic.woff); }

@font-face {
  font-display: swap;
  font-family: 'Bower Bold';
  font-style: normal;
  src: url(../fonts/Bower-Bold.woff); }

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #fafafe;
  font-family: 'McKinsey Sans Regular';
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a; }
  @media (max-width: 1300px) and (min-width: 1100px) {
    body,
    html {
      font-size: 14px; } }

a {
  color: #2251ff; }

strong {
  font-family: 'McKinsey Sans Medium';
  font-weight: normal; }

#root {
  width: 100%;
  height: 100%;
  display: block; }

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  -webkit-appearance: none; }

.txt-upper {
  text-transform: uppercase; }

.comp-modal {
  min-width: 500px !important; }

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f5f5f5;
  cursor: pointer; }

*::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  cursor: pointer; }

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #c1c1c1;
  cursor: pointer; }

section {
  display: flex; }
  section main {
    background-color: #fafafe;
    width: calc(100% - 80px);
    padding: 2% 4%;
    margin-left: 80px;
    padding-top: 30px; }

.ellipsisPro {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; }

.btn-container {
  text-align: center;
  width: 100%; }
  .btn-container span {
    color: #aaa; }
  .btn-container .btn + .btn,
  .btn-container span + .btn {
    margin-left: 20px; }

.currentPath {
  border: 1px solid #cccccc;
  display: block;
  color: #000000;
  font-size: 0.75rem;
  opacity: 0.4;
  padding: 10px; }

.allowedExt {
  display: block;
  color: #999;
  font-size: 0.75rem;
  padding: 10px 0; }

.section-header {
  font-family: 'McKinsey Sans Medium';
  line-height: normal;
  font-size: 1.375rem;
  display: inline-flex;
  justify-content: space-between; }

.blue-bar {
  height: 3.5px;
  background-color: #00a9f4;
  width: 40px;
  margin-top: 0;
  margin-bottom: 23px;
  border-radius: 0;
  display: none; }

.no-result {
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 20%;
  font-size: 1.25rem; }

.react-tabs {
  float: left;
  width: 100%; }
  .react-tabs > div {
    margin-top: 20px; }
  .react-tabs ul {
    padding-left: 0;
    /*  display: flex; */ }
  .react-tabs__tab {
    flex: 1;
    display: inline-block;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 5px 10px;
    font-size: 0.875rem;
    cursor: pointer;
    color: #cccccc;
    max-width: 150px;
    border-bottom: 1px solid #dddddd; }
    .react-tabs__tab--selected {
      background: #ffffff;
      color: #000000;
      font-weight: bold;
      border-bottom: 1px solid #2251ff; }
    .react-tabs__tab:focus {
      outline: none; }
      .react-tabs__tab:focus:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #ffffff; }
  .react-tabs__tab-panel {
    display: none; }
    .react-tabs__tab-panel--selected {
      display: block; }

.react-confirm-alert-body h1 {
  font-size: 1rem;
  font-weight: bold; }

.react-confirm-alert-body .react-confirm-alert-button-group {
  display: block;
  text-align: center; }

.react-confirm-alert-body .react-confirm-alert-button-group > button {
  background-color: #2251ff;
  border-color: #2251ff;
  color: #ffffff;
  border-radius: 0; }
  .react-confirm-alert-body .react-confirm-alert-button-group > button:hover {
    background-color: #0d3adf;
    border-color: #0d3adf; }

input::placeholder {
  color: #dddddd !important;
  font-style: italic; }

.extBox {
  text-align: center;
  border-radius: 12px;
  padding: 5px 8px;
  color: #222;
  background-color: #dddddd;
  vertical-align: -webkit-baseline-middle; }
  .extBox.doc {
    color: #0098cd;
    background-color: #ebf7fb; }
  .extBox.pdf {
    color: #d7073a;
    background-color: #ebf7fb; }
  .extBox.excel, .extBox.xls {
    color: #2db035;
    background-color: #ebf7fb; }
  .extBox.video {
    color: #4e95f4;
    background-color: #ebf7fb; }
  .extBox.png {
    color: #537535;
    background-color: #ebf7fb; }
  .extBox.txt {
    color: #8938b9;
    background-color: #ebf7fb; }
  .extBox.ppt, .extBox.pptx {
    color: #a78a21;
    background-color: #f3e7bb; }

.above-table-section {
  display: flex;
  justify-content: space-between; }
  .above-table-section .search-list-section {
    align-items: baseline;
    display: flex;
    margin-bottom: 0;
    flex-grow: 1; }
    .above-table-section .search-list-section > .form-group {
      display: flex;
      flex-grow: 1; }
      .above-table-section .search-list-section > .form-group input {
        border-radius: 0;
        height: 40px; }
    .above-table-section .search-list-section .showing-records {
      margin-left: 32px;
      font-size: 0.875rem;
      color: #000000; }
      @media (max-width: 767px) {
        .above-table-section .search-list-section .showing-records {
          margin-left: 16px;
          font-size: 0.75rem; } }
    .above-table-section .search-list-section + div {
      margin-bottom: 8px; }
  .above-table-section .action-icon {
    font-size: 1rem;
    margin-bottom: 8px; }
    .above-table-section .action-icon:not(:first-of-type) {
      margin-left: 10px; }
  .above-table-section button.search-icon {
    margin-left: 20px;
    margin-bottom: 5px; }
    .above-table-section button.search-icon svg {
      width: 15px;
      height: 15px;
      fill: white;
      margin-right: 5px;
      font-weight: bold; }

@media only screen and (max-width: 812px), only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .comp-modal {
    min-width: 90% !important; }
  section main {
    margin-left: 0; } }

input::-webkit-contacts-auto-fill-button {
  display: none !important;
  pointer-events: none; }

.total-rec {
  margin-left: 5px;
  color: #000000 !important; }

.d-flex {
  display: flex; }

.cursor {
  cursor: pointer; }

@media only screen and (max-width: 812px) and (orientation: portrait) {
  .above-table-section .search-list-section > .form-group {
    width: 40%; }
    .above-table-section .search-list-section > .form-group input {
      width: 100%; } }

.y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.xy-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.styles_overlay__CLSq- {
  background-color: rgba(5, 28, 44, 0.85); }

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer; }

b,
strong {
  font-weight: 500 !important; }

.btn {
  border-radius: 0; }
  .btn.btn-primary {
    background-color: #2251ff;
    border-color: #2251ff; }
    .btn.btn-primary:hover {
      background-color: #0d3adf; }

.btn-section {
  text-align: center; }
  .btn-section .btn {
    min-width: 136px;
    margin: 0 7px; }
    @media (max-width: 325px) {
      .btn-section .btn {
        width: 95pc; } }

@media (max-width: 767px) {
  .btn-lg {
    padding: 6px 12px;
    font-size: 1rem;
    line-height: 1.5; } }

.width136px {
  width: 136px; }

.react-confirm-alert-overlay {
  background: rgba(5, 28, 44, 0.85);
  font-family: 'McKinsey Sans Regular'; }
  .react-confirm-alert-overlay .react-confirm-alert-body {
    border-radius: 0; }

:root .ant-tabs-tab-prev-icon i,
:root .ant-tabs-tab-next-icon i {
  font-size: 1.375rem; }

:root .ant-tabs-tab-prev,
:root .ant-tabs-tab-next {
  color: #2251ff; }

:root .ant-tabs-tab-btn-disabled,
:root .ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25); }

:root .ant-tabs-tab-next-icon {
  margin-top: -4px; }

:root .ant-tabs-tab-prev-icon {
  margin-top: -5px; }

:root .ant-tabs-tab-prev-icon,
:root .ant-tabs-tab-next-icon {
  transform: translateY(-50%); }

:root .ant-tabs-tab-prev-icon {
  left: 0; }

:root .ant-tabs-tab-next-icon {
  right: 0; }

.trash-black svg > g > g {
  fill: #000000; }

.dropzone {
  border-radius: 0 !important; }

.css-bg1rzq-control {
  border-radius: 0 !important; }

.css-1szy77t-control {
  border-radius: 0 !important;
  outline: none !important;
  border: 1px solid #80bdff !important;
  box-shadow: 0 0 0 3.2px rgba(0, 123, 255, 0.25) !important; }

.btn-outline-primary:hover {
  background-color: #2251ff;
  border-color: #2251ff; }

.RichTextEditor__root___2QXK- {
  font-family: 'McKinsey Sans Regular' !important;
  font-size: 14px !important; }

.public-DraftStyleDefault-block {
  font-family: 'McKinsey Sans Regular' !important;
  font-size: 14px !important; }

.main-section-class {
  padding-top: 70px;
  display: block; }
  @media (max-width: 767px) {
    .main-section-class {
      padding-top: 59px; } }
