$site-text-color: #4a4a4a;
$black-third: $site-text-color;

$site-blue: #2251ff;
$site-blue-new: #2251ff;
$blue-third: $site-blue;
$icons-color: $site-blue;
$font-family: 'McKinsey Sans Regular';
$font-family-light: 'McKinsey Sans Light';
$font-family-bold: 'McKinsey Sans Medium';
// Colors
$gray: gray;
$white: #ffffff;
$white-second: #f9f9f9;
$bg-color: #fafafe;
$white-third: #dfe1e7;
$white-fourth: #eee;
$white-fifth: #fbe9e7;
$white-sixth: #dfe3e9;
$white-seventh: #ced4da;
$white-eighth: #e7e9ea;
$white-ninth: #b8b8b8;
$white-tenth: #f0f4ff;
$white-eleventh: #999;
$white-twelfth: #ebf7fb;
$white-thirteenth: #e1edfe;
$white-fourteenth: #d2d3d3;
$white-fifteenth: #e4e4e4;
$white-sixteen: #dbdbdb;
$white-seventheen: #f8f9f9;
$white-eighteenth: #f6f6f6;
$white-ninetheenth: #f0f6f8;
$white-tweentyth: #efefef;
$white-tweentyone: #f8fafd;

$black: #000000;
$black-second: #4a4a4a;
$black-fourth: #323232;
$black-fifth: #222;
$black-sixth: #191920;
$black-seventh: #333333;
$black-eighth: #d5d5d5;
$black-ninth: #3c3c5c;
$black-tenth: #979797;
$black-eleventh: #191920;
$black-twelve: #323c47;
$black-thirteenth: #2d2d2d;

$gray: #aaa;
$gray-second: #cccccc;
$gray-light: #dddddd;
$gray-lighter: #666666;
$gray-more-light: #808080;
$gray-third: #e6eaee;
$gray-fourth: #d8d8d8;
$gray-fifth: #bcbdbe;
$gray-sixth: #f5f5f5;
$gray-seventh: #402710;
$gray-eighth: #e5e5ea;
$gray-ninth: #dcdcdc;
$gray-tenth: #f4f4f4;
$gray-eleven: #f2f2f2;
$gray-twelve: #e3e1e1;
$gray-thirteen: #d2d2d2;
$gray-forteen: #cdcdcd;
$gray-fifteen: #a7a7a7;
$gray-sixteen: #e9eff4;
$gray-seventeen: #e6e6e6;
$gray-bluish: #495057;
$blue: #2251ff;
$blue-main: #0000ff;
$blue-second: #3b99fc;
$blue-fourth: #3d67e4;
$blue-fifth: #439de6;
$blue-sixth: #0056b3;
$blue-light-b: #2251ff;
$blue-seventh: #007bff;
$blue-eighth: #005aff;
$blue-ninth: #0098cd;
$blue-tenth: #7895ec;
$blue-eleventh: #30b9fd;
$blue-twelfth: #306bfd;
$blue-thirteenth: #4ae2ea;
$blue-fourteenth: #3c5fe3;
$blue-sixteenth: #4e95f4;
$blue-seventeenth: #306efd;
$blue-eighteen: #d8ebff;
$blue-ninetheen: #4a90e2;
$blue-ninteen: #f7fbff;
$blue-lightest: #dbedff;
$blue-lightest2: #4accea;
$blue-validation: #80bdff;
$blue-twenty: #e9eff4;

$blue-dark: #354052;
$blue-light: #7f8fa4;

$primary-color: #4dbdfe;

$cyan-one: #29cbe7;

$font-color: #2d3649;

$red: #ff0000;
$red-second: #d84315;
$red-third: #f08463;
$red-fourth: #ff6d4a;
$red-fifth: #aa3510;
$red-sixth: #d7073a;

$green-one: #2db035;
$green-two: #537535;
$green-three: #2dd88e;

$violet-one: #8938b9;

$yellow-one: #a78a21;
$yellow-two: #f3e7bb;
$light-greyish: #f7f7f7;
$border-radius: 6px;

$font-weight-label: 500;

$shadow-multiple: 5px -5px 0 #ccc, 10px -10px 0 #ddd, 15px -15px 0 #eee;
