header {
  width: 100%;
  height: 70px;
  background: #ffffff;
  /* border-bottom: 1px solid $gray-light; */
  display: inline-flex !important;
  position: fixed;
  z-index: 1001;
  box-shadow: 1px 1px 4px 0 rgba(190, 190, 190, 0.5); }
  header > .logo-container {
    padding: 10px 20px;
    width: 150px;
    position: relative;
    margin-right: 20px; }
    header > .logo-container:after {
      content: '';
      width: 1px;
      height: calc(100% - 30px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      background-color: #ebefff; }
    header > .logo-container a {
      float: left;
      width: 100%;
      font-size: 1.125rem;
      color: #0056b3;
      font-family: serif; }
      header > .logo-container a:hover {
        text-decoration: none; }
    header > .logo-container label {
      font-size: 0.75rem;
      color: #29cbe7; }
    header > .logo-container .mck-logo-icon {
      /* background: url('../../Assets/group@3x_new.jpg'); */
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      padding-top: 8px; }
      @media (max-width: 767px) {
        header > .logo-container .mck-logo-icon img {
          width: 100%;
          height: auto; } }
    header > .logo-container .mck-mm-title {
      font-size: 0.6875rem !important; }
    @media (max-width: 1023px) {
      header > .logo-container {
        padding: 10px 10px 10px 10px;
        width: 125px;
        margin-right: 10px; } }
    @media (max-width: 767px) {
      header > .logo-container {
        padding: 6px 10px 0 10px;
        width: 91px;
        margin-right: 4px; } }
  @media (max-width: 767px) {
    header #leftLogo {
      width: 60px;
      height: auto; } }
  header .searchIcon {
    position: absolute;
    margin-top: 24px;
    right: 55px; }
    header .searchIcon > a {
      padding: 24px 40px;
      height: 69px; }
      @media (max-width: 767px) {
        header .searchIcon > a {
          padding: 2px 10px;
          height: auto;
          display: block; } }
      header .searchIcon > a svg {
        fill: #aaa; }
    @media (max-width: 767px) {
      header .searchIcon {
        margin-top: 17px; } }
  @media (max-width: 767px) {
    header {
      height: 58px; } }

.tagline {
  line-height: 70px;
  color: #2251ff;
  font-size: 0.875rem; }
  @media (max-width: 1023px) {
    .tagline {
      width: 130px;
      line-height: normal;
      margin-top: 18px; } }
  @media (max-width: 767px) {
    .tagline {
      max-width: 119px;
      margin-top: 17px;
      font-size: 0.6875rem; } }

ul.lopNavBar {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 80px;
  /* &.lop-mobile-nav{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        background-color: #FFFFFF;
        box-shadow: 2px 0 10px 0 rgba(228,228,228,0.6);
        li{
            padding: 0;
            width: 50%;
            text-align: center;
            font-size: 0.75rem;
            line-height: 40px;
            &:first-child{
                border-right: 1px solid rgba(#979797, 0.12);
            }
        }
    } */ }
  ul.lopNavBar li {
    float: left;
    font-size: 0.875rem;
    position: relative;
    padding: 24px 20px;
    cursor: pointer;
    color: #4a4a4a; }
    ul.lopNavBar li:first-child {
      padding-left: 0 !important; }
    ul.lopNavBar li.publishLink {
      padding-top: 27px; }
      ul.lopNavBar li.publishLink span {
        margin-left: 4px;
        font-weight: 500; }
    ul.lopNavBar li.ctawinroom {
      padding-top: 27px; }
      ul.lopNavBar li.ctawinroom span {
        margin-left: 4px;
        font-weight: 500; }
    ul.lopNavBar li .addIcon {
      margin-right: 8px;
      font-weight: normal;
      text-align: center;
      font-size: 0.875rem; }
    ul.lopNavBar li a {
      color: inherit;
      text-decoration: none; }
    ul.lopNavBar li.createLopLink a {
      background: #2251ff;
      color: #ffffff;
      display: inline-block;
      padding: 10px 16px;
      position: relative;
      top: -10px; }
      @media (max-width: 1023px) {
        ul.lopNavBar li.createLopLink a {
          padding: 7px 7px;
          top: -6px; } }
  ul.lopNavBar .dropdown > button {
    color: #000000;
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    padding: 0;
    margin: 0;
    font-weight: 500; }
    ul.lopNavBar .dropdown > button svg {
      fill: #2251ff; }
  ul.lopNavBar .dropdown.show .dropIcon {
    left: 30px;
    top: 28px;
    background: #ffffff;
    position: absolute;
    box-shadow: 0 0 10px 0 rgba(228, 228, 228, 0.6);
    width: 16px;
    height: 16px;
    transform: rotate(45deg); }
  ul.lopNavBar .dropdown .searchLopContainer {
    position: relative;
    width: 100%;
    padding: 12px;
    top: 0; }
    ul.lopNavBar .dropdown .searchLopContainer .searchContentOuter {
      background: #f5f5f5;
      padding: 8px 12px 6px 34px;
      border-radius: 18px;
      position: relative; }
    ul.lopNavBar .dropdown .searchLopContainer svg {
      position: absolute;
      width: 12px;
      height: 12px;
      left: 11px;
      top: 12px; }
    ul.lopNavBar .dropdown .searchLopContainer input {
      width: 100%;
      border: 0;
      outline: none;
      background: none;
      font-size: 0.875rem;
      line-height: 14px; }
  ul.lopNavBar .dropdown .dropdown-menu {
    top: 12px !important;
    left: -120px !important;
    border: 0;
    box-shadow: 0 0 10px 0 rgba(228, 228, 228, 0.6);
    min-height: 120px;
    min-width: 250px;
    max-width: 300px; }
  ul.lopNavBar .dropdown .mainLopList {
    max-height: 238px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  ul.lopNavBar .dropdown .loaderLopList {
    height: 170px;
    position: relative; }
    ul.lopNavBar .dropdown .loaderLopList .loader-overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.15); }
      ul.lopNavBar .dropdown .loaderLopList .loader-overlay .lds-roller {
        transform: none;
        margin-left: -35px;
        margin-top: -45px; }
  ul.lopNavBar .dropdown div > button {
    border-bottom: 1px solid #e6eaee;
    padding: 0;
    cursor: pointer; }
    ul.lopNavBar .dropdown div > button:active {
      background-color: #ffffff;
      outline: none;
      color: inherit; }
    ul.lopNavBar .dropdown div > button:last-child {
      border: 0; }
    ul.lopNavBar .dropdown div > button:first-child {
      border-top: 1px solid #e6eaee; }
    ul.lopNavBar .dropdown div > button a {
      padding: 12px 24px;
      display: block;
      color: #2d3649;
      opacity: 0.8;
      max-width: 100%;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      white-space: nowrap; }
      ul.lopNavBar .dropdown div > button a:hover {
        color: #2251ff;
        opacity: 1; }

.logout.btn {
  height: 38px;
  width: 82px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: 1px solid #dddddd; }

.sign-out:before {
  content: '\21BA'; }

.welcome-div {
  position: absolute;
  right: 147px; }
  @media (max-width: 767px) {
    .welcome-div {
      display: none; } }

.welcomeName {
  margin-top: 22px;
  right: 16%;
  font-size: 1rem;
  color: #2251ff;
  text-transform: capitalize; }

.welcomeText {
  color: #000000; }

.welcomeText {
  color: #000000; }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  display: block;
  left: auto;
  right: 0; }
  .dropdown-content p {
    padding: 5px; }

.dropdown-content p:hover {
  color: #ffffff;
  background-color: #2251ff;
  padding: 5px; }

.dropdown-content a {
  text-decoration: none !important;
  color: #4a4a4a; }

.buttoncss {
  border-radius: 5px;
  border: 1px solid #808080;
  height: 35px;
  margin-top: 43%;
  width: 35px;
  margin-right: 5px;
  padding: 0;
  text-align: center; }
  @media (max-width: 992px) {
    .buttoncss {
      margin-top: 18px; } }
  @media (max-width: 767px) {
    .buttoncss {
      margin-top: 13px; } }

.dropdown button svg {
  fill: #aaa; }

.action-icon {
  cursor: pointer; }

.user-logout {
  position: absolute;
  right: 25px; }
  @media (max-width: 767px) {
    .user-logout {
      right: 12px; } }

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* header {
        .searchIcon > a {
            padding: 24px 28px;
            height: 69px;
        }

        .logout.btn {
            width: 30px;
            right: 10px;
        }
    } */ }

@media only screen and (max-width: 767px), only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  /* header > .logo-container {
        padding: 12px 20px;
        width: 100%;
    } */ }

@media only screen and (max-width: 360px), only screen and (max-width: 640px) and (min-width: 360px) and (orientation: portrait) {
  /* header .searchIcon > a {
        padding: 24px 11px;
        height: 69px;
    } */ }

@media only screen and (max-width: 767px), only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
  /* header > .logo-container {
        padding: 12px 20px;
        width: 100%;
    } */ }

@media only screen and (max-width: 360px), only screen and (max-width: 640px) and (min-width: 360px) and (orientation: landscape) {
  /* header .searchIcon > a {
        padding: 24px 11px;
        height: 69px;
    } */ }

@media only screen and (max-width: 767px), only screen and (max-width: 1024px) and (min-width: 768px) {
  /* header > .logo-container {
        padding: 12px;
        width: 100%;
    }

    header .searchIcon {
        position: absolute;
        margin-top: 22px;
        right: 10%;
    }

    .user-logout {
        right: 10px;
        top: 3px;
    }

    .buttoncss {
        height: 33px;
        width: 33px;
    } */ }

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  /* header .searchIcon {
        position: absolute;
        margin-top: 22px;
        right: 5%;
    } */ }

@media (max-width: 1024px) {
  /* ul.lopNavBar {
        li {
            font-size: 0.75rem;
            padding: 24px 15px;
        }

        .dropdown {
            & > button {
                font-size: 0.75rem;
            }
        }
    } */ }

@media (max-width: 768px) {
  /* header > .logo-container {
        a {
            font-size: 0.75rem;
        }
        label {
            font-size: 0.625rem;
        }
    }
     */
  ul.lopNavBar {
    /* right: 44px; */ }
    ul.lopNavBar li {
      padding: 24px 8px;
      font-size: 0.5875rem; }
      ul.lopNavBar li svg {
        width: 16px;
        height: 16px; }
      ul.lopNavBar li .btnCreateLop {
        font-size: 0.6875rem; }
      ul.lopNavBar li.publishLink {
        padding-top: 27px; }
        ul.lopNavBar li.publishLink span {
          margin-left: 2px;
          font-weight: 500; }
      ul.lopNavBar li.ctawinroom {
        padding-top: 27px; }
        ul.lopNavBar li.ctawinroom span {
          margin-left: 2px;
          font-weight: 500; }
    ul.lopNavBar .dropdown > button {
      font-size: 0.625rem;
      margin-top: 2px; }
    ul.lopNavBar .dropdown div > button a {
      padding: 12px 24px;
      font-size: 0.625rem; } }

@media only screen and (min-width: 275px) and (max-width: 875px) and (max-height: 875px), (-webkit-device-pixel-ratio: 3) {
  ul.lopNavBar {
    right: 60px;
    top: 13px; }
    ul.lopNavBar li {
      display: none; }
      ul.lopNavBar li.lopDropDown {
        display: inherit;
        padding: 0; }
        ul.lopNavBar li.lopDropDown .dropdown .loaderLopList {
          height: 160px; }
        ul.lopNavBar li.lopDropDown .dropdown > button {
          box-shadow: 0 0 2px 0 #b8b8b8 !important;
          width: 34px;
          height: 34px;
          font-size: 0.4375rem;
          border-radius: 4px; }
          ul.lopNavBar li.lopDropDown .dropdown > button svg {
            display: block;
            left: 50%;
            position: relative;
            margin-left: -8px;
            width: 16px;
            height: 16px;
            margin-bottom: 1px; }
        ul.lopNavBar li.lopDropDown .dropdown.show .dropIcon {
          display: none; }
        ul.lopNavBar li.lopDropDown .dropdown .dropdown-menu {
          position: fixed !important;
          top: 57px !important;
          left: 0 !important;
          width: 100%;
          transform: translate3d(0px, 0px, 0px) !important;
          min-height: 100%;
          min-width: 80%;
          max-width: 80%;
          border-radius: 0;
          animation: 0.3s ease-in-out alternate slidein; }
          ul.lopNavBar li.lopDropDown .dropdown .dropdown-menu button a {
            font-size: 0.75rem;
            font-weight: 500; }
          ul.lopNavBar li.lopDropDown .dropdown .dropdown-menu .mainLopList {
            max-height: 160px;
            margin-right: 8px; }
  @keyframes slidein {
    from {
      min-width: 0;
      max-width: 0; }
    to {
      min-width: 80%;
      max-width: 80%; } } }

@media (max-width: 440px) {
  ul.lopNavBar li.lopDropDown .dropdown .loaderLopList {
    height: calc(100vh - 138px); }
  ul.lopNavBar li.lopDropDown .dropdown .dropdown-menu .mainLopList {
    max-height: calc(100vh - 140px);
    margin-right: 2px; } }
